<template>
  <div>
    <div id="locsearchcomponent">
      <MetaboliteBrowseCompnentSearch :headT='head'></MetaboliteBrowseCompnentSearch>
      <el-row>
        <el-col :span="1">
          <div class="grid-content bg-purple"></div>
        </el-col>

        <el-col :span="22">
          <el-steps :active="step" align-center>
            <el-step title="Step 1" description="Specie selection"></el-step>
            <el-step title="Step 2" description="Chromosome selection"></el-step>
            <el-step title="Step 3" description="Locu interval"></el-step>
            <el-step title="Step 4" description="Results"></el-step>
          </el-steps>
          <div class="itemselection">
            <h2>
              Introduction
            </h2>
            <div class="speciesselection" style="margin-top: 20px">
              <p style="font-size: 20px;">
                'Locus to Metabolites' allows researchers to identify metabolites associated with query regions derived
                from genetic analysis of agronomic traits. By specifying a particular interval on a selected chromosome
                of a species,
                PMhub will provide all the features associated with the SNPs/variations located within that interval.
                For each item,a genetic analysis webpage is attached.
              </p>
            </div>
          </div>
          <div class="itemselection">
            <h2>
              Species selection
            </h2>
            <div class="speciesselection" style="margin-top: 20px">
              <el-radio-group v-model="species">
                <el-radio-button label="trae">{{this.$store.state.speciesDict['trae']}}</el-radio-button>
                <el-radio-button label="zema">{{this.$store.state.speciesDict['zema']}}</el-radio-button>
                <el-radio-button label="orsa">{{this.$store.state.speciesDict['orsa']}}</el-radio-button>
                <el-radio-button label="lyes">{{this.$store.state.speciesDict['lyes']}}</el-radio-button>
              </el-radio-group>
            </div>
          </div>
          <div class="itemselection" v-if='chromosomeInfo.length > 0'>
            <h2>
              Chromosome selection
            </h2>
            <div class="speciesselection" style="margin-top: 20px">
              <el-radio-group v-model="chromosome">
                <el-radio-button :label="items.id" v-for="items in chromosomeInfo">{{items.label}}</el-radio-button>
              </el-radio-group>
            </div>
          </div>
          <div class="itemselection" v-if='chromosome'>
            <h2>
              Locu interval
            </h2>
            <div class="speciesselection" style="margin-top: 20px">

              <div class="slider-demo-block" style="width:400px">
                <el-input v-model="intervel.left" placeholder="Please input left position">
                  <template #prepend style="width:200px">Left position (Mb)</template>
                </el-input>

                <el-input v-model="intervel.right" placeholder="Please input right position">
                  <template #prepend style="width:200px">Right position (Mb)</template>
                </el-input>
                <el-button type="primary" :icon="Search" :loading="loading" @click="search">Search
                  <Search style="width:12; height:12" />
                </el-button>
                <!-- <el-slider v-model="intervel.left" show-input :max="chromosomeSelected.length"> </el-slider> -->
                <!-- <el-slider v-model="intervel.right" show-input :max="chromosomeSelected.length"> </el-slider> -->

              </div>

            </div>
          </div>
          <div class="itemselection" v-if="result.length>0">
            <h2>
              Results<Tips message="This section contains the features for which SNPs/variations associated with them are within the selected genome interval.<br>
                <br><b>-log10(p)</b>: The translated value of p-value of a selected mGWAS (metabolome-based genome-wide association studies) result.<br>
                <b>RT</b>: Retention time.<br>
                <b>Q1</b>: Parent Ion Mass.<br>
                <b>Article annotation</b>: The result of the published research, which includes both standard and putative annotation.<br>
                <b>Library annotation</b>: The result by searching the querying MS/MS data of the selected feature in standard MS/MS library of PMhub.<br>
                <b>Library annotation score</b>: A modified Dot-product function used to score the similarity between the experimental spectrum and
                  the standard spectrum in the library<br>
                " :width="400"></Tips>
            </h2>
            <div class="speciesselection" style="margin-top: 20px">
              <el-table :data="result" style="width: 100%" @row-click="detailSearch" @header-click="sortTable">
                <el-table-column label="Feature ID" width="180">
                  <template #default="scope">
                    <!-- <p>{{ scope.row.featureID }}</p> -->
                    <a :href="$store.state.frontbaseURL+'featuredetail/'+scope.row.featureID">{{ scope.row.featureID
                      }}</a>
                  </template>
                </el-table-column>
                <el-table-column prop="pvalue2__max" label="-log10(p)"></el-table-column>
                <el-table-column prop="rt" label="RT"></el-table-column>
                <el-table-column prop="q1" label="Q1"></el-table-column>
                <el-table-column prop="mode" label="Mode"></el-table-column>
                <!-- <el-table-column prop="ionizationModel" label="Ion"></el-table-column> -->
                <el-table-column prop="metabolite" label="Article annotation"></el-table-column>
                <el-table-column prop="identificationLevel" label="Article annotation Level"></el-table-column>
                <el-table-column prop="name" label="Article annotation name"></el-table-column>
                <el-table-column prop="metabolite2" label="Library annotation"></el-table-column>
                <el-table-column prop="alignScore" label="Library annotation score"></el-table-column>
                <el-table-column prop="name2" label="Library annotation name"></el-table-column>
              </el-table>
            </div>
          </div>
        </el-col>
      </el-row>

    </div>
  </div>
</template>
<!--  prop="metabolite" -->
<script>
  import request from '@/network/request'
  import $ from 'jquery'
  import ChemDoodle from "../../mymodules/ChemDoodleWeb-9.2.0/install/ChemDoodleWeb.js"
  import MetaboliteBrowseCompnentSearch from "@/components/Browse/MetaboliteBrowseCompnentSearch.vue"
  import { ref } from 'vue'
  import { Edit, Upload, Picture, Search } from '@element-plus/icons-vue'
  import Tips from '@/components/Common/Tips.vue'
  let canvas
  let context1
  export default {
    name: 'LocSearchComponent',
    data () {
      return {
        species: '',
        step: 0,
        chromosomeInfo: [],
        result: [],
        chromosome: '',
        chromosomeSelected: {},
        intervel: { 'left': 0, 'right': 5 },
        loading: false,
        head: "Locus to Metabolites"
      }
    },
    methods: {
      test () {
        this.count += 1
      },
      getChromosomeInfo (seachParams) {
        request({
          url: 'Chromosome/list/',
          params: seachParams,
        }).then(res => {

          this.chromosomeInfo = res
          this.chromosomeInfo.sort((a, b) => a.id - b.id)
        }).catch(err => {
          console.log(err);
        });
      },
      search () {
        let seachParams = { species: this.species, chromosome: this.chromosome, left: this.intervel.left * 10 ** 6, right: this.intervel.right * 10 ** 6 }
        this.loading = true
        if (seachParams.right - seachParams.left <= 5000000) {
          request({
            url: 'Intervel/gwas/',
            params: seachParams,
          }).then(res => {

            var resultTemp = res
            var annoDict = {}
            for (let i = 0; i < resultTemp.featureInfo.length; i++) {
              annoDict[resultTemp.featureInfo[i].featureID] = resultTemp.featureInfo[i]
            }
            this.result = []

            for (let i = 0; i < resultTemp.gwas.length; i++) {
              this.result.push({ ...resultTemp.gwas[i], ...annoDict[resultTemp.gwas[i].featureID] })
            }
            this.loading = false
            this.step = 3
          }).catch(err => {
            console.log(err);
            this.loading = false
          });
        } else {
          console.log("err")
          this.loading = false
        }
      }
    },
    components: {
      MetaboliteBrowseCompnentSearch,
      Edit,
      Upload,
      Picture,
      Search,
      Tips,
    },
    created () {

    },
    mounted () {
      window.vue = this
    },
    updated () {

    },
    // 检测querydata的编号
    watch: {
      species: {
        handler (val, oldVal) {
          let seachParams = { "species": val }
          this.getChromosomeInfo(seachParams)
          this.chromosome = ''
          this.step = 1
          this.result = []
        }
      },
      chromosome: {
        handler (val, oldVal) {
          this.chromosomeSelected = this.chromosomeInfo.filter(d => d.id == this.chromosome)[0]
          this.result = []
          if (val) {
            this.step = 2
          }
        }
      },
      intervel: {
        handler (val, oldVal) {

        }
      }
    }
  }
</script>

<style scoped lang="scss">
  #locsearchcomponent {
    min-height: 800px
  }

  .el-main {
    background-color: #e9eef3;
    color: #333;
    text-align: center;
  }

  .el-select .el-input {
    width: 180px;
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

  .el-table {
    cursor: pointer;
  }


  .el-input {
    margin: 1px 0px 3px 0px;
  }

  h3 {
    height: 15px;
    margin: 30px 0px 20px 0px;
  }

  /* .el-slider {
    ::v-deep .el-slider__input {
      width: 200px;
      float: none;
    }
  } */

  .el-slider {
    :deep(.el-slider__input) {
      width: 200px;
      float: none;
    }
  }

  /* .rightslider>>>.el-slider>>>.el-slider__input {
    width: 200px;
    float: none;
  } */
</style>